import React, { useState, useEffect } from 'react'
import Profiles from './profiles';

export default function Board() {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const [period, setPeriod] = useState(0);
    const [posts, setPosts] = useState([]);
    useEffect(() => {
        fetch('https://f00d.me/cookieapi/leaderboard')
           .then((response) => response.json())
           .then((data) => {
              // Rank the users
              data.forEach((user, index) => {
                  user.rank = index + 1;
              });
            //   console.log(data);
              setPosts(data);
           })
           .catch((err) => {
              console.log(err.message);
           });
     }, []);
     function paginate(data) {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return data.slice(startIndex, startIndex + itemsPerPage);
    }

  const handleClick = (e) => {

    setPeriod(e.target.dataset.id)
    setCurrentPage(1);
  }

  return (

    <div className="board">
        <h1 className='leaderboard'>
            <div className="logo"></div>LeaderBoard</h1>


        <div className="duration">
            <button onClick={handleClick} data-id='7'>7 Days</button>
            <button onClick={handleClick} data-id='30'>30 Days</button>
            <button onClick={handleClick} data-id='0'>All-Time</button>
        </div>

        <Profiles
    posts={paginate(between(posts, period))}
    currentPage={currentPage}
    itemsPerPage={itemsPerPage}
/>

        <div className="pagination-controls">
    <button onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 1}>Previous</button>
    <span>Page {currentPage}</span>
    <button onClick={() => setCurrentPage(currentPage + 1)} disabled={currentPage * itemsPerPage >= posts.length}>Next</button>
</div>


    </div>
  )
}



function between(data, between){
    const today = new Date();
    const previous = new Date(today);
    previous.setDate(previous.getDate() - (between + 1));

    let filter = data.filter(val => {
        let userDate = new Date(val.updatedAt);
        if (between == 0) return val;
        return previous <= userDate && today >= userDate;
    })

    // sort with ascending order
    return filter.sort((a, b) => {
        if ( a.score === b.score){
            return b.score - a.score;
        } else{
            return b.score - a.score;
        }
    })

}
