import React from 'react'

export default function Profiles({ posts, currentPage, itemsPerPage }) {
    return (
        <div id="profile">
            {posts.map((post, index) => (
                <Item
                    key={post.id}
                    post={post}
                    rank={(currentPage - 1) * itemsPerPage + index + 1}
                />
            ))}
        </div>
    );
}

function Item({ post, rank }) {
    return (
        <div className="flex panel">
            <div className="item">
                <span>{rank}. </span>
                <div className="info">
                    <h3 className='name text-dark'>{post.name}</h3>
                    {/* <span>{post.location}</span> */}
                </div>
            </div>
            <div className="item">
                <h3>{post.score}</h3>
            </div>
        </div>
    );
}
